.logo {
    img {
        transform: scale(1.3);
    }
}

video#myVideo {
    position: absolute;
    z-index: 4;
    right: 0;
    bottom: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    background-color: black;
    background-position: center center;
    background-size: contain;
    object-fit: cover;
}

video#myVideo2 {
    position: absolute;
    z-index: 4;
    right: 0;
    bottom: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    background-color: black;
    background-position: center center;
    background-size: contain;
    object-fit: cover;

    // position: absolute !important;
    top: calc(50% + 30px);
    left: 50%;
    min-width: 100vw;
    min-height: calc(100vh + 250px);
    width: auto;
    height: auto;
    z-index: 0;
    transform: translateX(-50%) translateY(-50%);

    @include mq('desktop-wide', min) {
        min-height: calc(100vh + 400px);
    }
    @include mq('desktop-large', min) {
        min-height: calc(100vh + 700px);
    }
}

#section7-right {
    z-index: 9999 !important;
    position: relative !important;
    min-width: 100% !important;
}



.action {
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    margin: 2rem;
    color: var(--color-action);
    border: 0;
    padding: 0;
    opacity: 0;
    pointer-events: none;
}

.action:focus {
    outline: none;
}


.hidden {
    position: absolute;
    overflow: hidden;
    width: 0;
    height: 0;
    pointer-events: none;
}

/* Icons */
.icon {
    display: block;
    width: 2em;
    height: 2em;
    margin: 0 auto;
    fill: currentColor;
}