.section-title {
    font-size: 40px;
    font-family: "Garamond-Bold";
    color: rgb(255, 255, 255);
    line-height: 1.2;
    text-align: center;
    padding-bottom: 20px;
    padding-top: 5px;
    text-transform: uppercase;

    position: relative;

    &::after {
        content: "";
        background-color: rgb(197, 179, 88);
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        width: 35px;
        height: 2px;
    }
}

.section-subtitle-one {
    font-size: 16px;
    font-family: "Garamond-Regular";
    color: rgb(255, 255, 255);
    line-height: 2.222;
    text-align: center;
    letter-spacing: 4px;
}

.section-subtitle-two {
    font-size: 30px;
    font-family: "AndantinoScript";
    color: rgb(185, 184, 185);
    line-height: 0.933;
    text-align: center;
}

.section-paragraph {
    padding: 0 75px;
    font-size: 14px;
    font-family: "Montserrat-Light";
    color: rgb(255, 255, 255);
    line-height: 1.714;
    text-align: center;

    &__yellow {
        &::first-line {
            font-family: "Montserrat-Regular";
            color: $my-yellow;
            text-transform: uppercase;
        }

        margin-bottom: 30px;
    }

    &__yellowed {
        margin-bottom: 20px;
        color: $my-yellow;
        font-family: "Montserrat-Regular";
        color: $my-yellow;
        text-transform: uppercase;
    }
}

.phone-number {
    position: absolute;
    top: 20px;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .phone {
        margin: 0 20px;
    }

    a {
        font-size: 14px;
        font-family: "Montserrat-Regular";
        color: rgb(178, 177, 178);
        line-height: 2;
    }
}

.phone a {
    font-size: 20px;
}