.my-preloader {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: fixed;
    z-index: 999999999;
    width: 100%;
    height: 100%;
    // background-image: url('../img/menu_bg.png');
    background-color: #000;
    @extend %bgcover;
  }
  
  .preloader_logo {
    display: flex;
    justify-content: center;
  }
  
  .cube, .half1, .half2, .container {
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
  
//   .container {
//     position: relative;
//     width: 100px;
//     height: 100px;
//     margin: 150px auto 0;
//     -webkit-perspective: 300px;
//     -moz-perspective: 300px;
//     perspective: 300px;
//   }
  
  .cube {
    width: 100px;
    height: 100px;
  }
  
  .cube {
    -webkit-animation: cube 10s forwards infinite;
    -moz-animation: cube 10s forwards infinite;
    animation: cube 10s forwards infinite;
    -webkit-transform-origin: center 50px;
    -moz-transform-origin: center 50px;
    -ms-transform-origin: center 50px;
    -o-transform-origin: center 50px;
    transform-origin: center 50px;
  }
  
  .half1 {
    height: 40px;
    top: 0;
    position: absolute;
    -webkit-animation: half-fold 10s forwards infinite;
    -moz-animation: half-fold 10s forwards infinite;
    animation: half-fold 10s forwards infinite;
    -webkit-transform-origin: 50% 100%;
    -moz-transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    -o-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }
  
  .side {
    width: 20px;
    height: 20px;
    background: #6BD6DD;
    position: absolute;
  }
  
  .s1 {
    top: 0;
    left: 40px;
    -webkit-transform-origin: 50% 100%;
    -moz-transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    -o-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-animation: s1ani 10s forwards infinite;
    -moz-animation: s1ani 10s forwards infinite;
    animation: s1ani 10s forwards infinite;
  }
  
  .s2 {
    top: 20px;
    left: 40px;
    -webkit-transform-origin: 50% 0%;
    -moz-transform-origin: 50% 0%;
    -ms-transform-origin: 50% 0%;
    -o-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-animation: s2ani 10s forwards infinite;
    -moz-animation: s2ani 10s forwards infinite;
    animation: s2ani 10s forwards infinite;
  }
  
  .s3 {
    top: 40px;
    left: 40px;
    -webkit-transform-origin: 50% 0%;
    -moz-transform-origin: 50% 0%;
    -ms-transform-origin: 50% 0%;
    -o-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-animation: s3ani 10s forwards infinite;
    -moz-animation: s3ani 10s forwards infinite;
    animation: s3ani 10s forwards infinite;
  }
  
  .s4 {
    top: 60px;
    left: 40px;
    -webkit-transform-origin: 50% 0%;
    -moz-transform-origin: 50% 0%;
    -ms-transform-origin: 50% 0%;
    -o-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-animation: s4ani 10s forwards infinite;
    -moz-animation: s4ani 10s forwards infinite;
    animation: s4ani 10s forwards infinite;
  }
  
  .s5 {
    left: 20px;
    top: 20px;
    -webkit-transform-origin: 100% 50%;
    -moz-transform-origin: 100% 50%;
    -ms-transform-origin: 100% 50%;
    -o-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-animation: s5ani 10s forwards infinite;
    -moz-animation: s5ani 10s forwards infinite;
    animation: s5ani 10s forwards infinite;
  }
  
  .s6 {
    left: 60px;
    top: 40px;
    -webkit-transform-origin: 0% 50%;
    -moz-transform-origin: 0% 50%;
    -ms-transform-origin: 0% 50%;
    -o-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    -webkit-animation: s6ani 10s forwards infinite;
    -moz-animation: s6ani 10s forwards infinite;
    animation: s6ani 10s forwards infinite;
  }
  
  @-webkit-keyframes s1ani {
    0% {
      opacity: 1;
      -webkit-transform: translateY(0px);
    }
    10% {
      opacity: 1;
    }
    20% {
      opacity: 1;
    }
    30% {
      opacity: 1;
    }
    40% {
      -webkit-transform: rotateX(0deg);
    }
    50% {
      -webkit-transform: rotateX(-90deg);
    }
    80% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes s1ani {
    0% {
      opacity: 1;
      -moz-transform: translateY(0px);
    }
    10% {
      opacity: 1;
    }
    20% {
      opacity: 1;
    }
    30% {
      opacity: 1;
    }
    40% {
      -moz-transform: rotateX(0deg);
    }
    50% {
      -moz-transform: rotateX(-90deg);
    }
    80% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes s1ani {
    0% {
      opacity: 1;
      -webkit-transform: translateY(0px);
      -moz-transform: translateY(0px);
      -ms-transform: translateY(0px);
      -o-transform: translateY(0px);
      transform: translateY(0px);
    }
    10% {
      opacity: 1;
    }
    20% {
      opacity: 1;
    }
    30% {
      opacity: 1;
    }
    40% {
      -webkit-transform: rotateX(0deg);
      -moz-transform: rotateX(0deg);
      -ms-transform: rotateX(0deg);
      -o-transform: rotateX(0deg);
      transform: rotateX(0deg);
    }
    50% {
      -webkit-transform: rotateX(-90deg);
      -moz-transform: rotateX(-90deg);
      -ms-transform: rotateX(-90deg);
      -o-transform: rotateX(-90deg);
      transform: rotateX(-90deg);
    }
    80% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes s2ani {
    0% {
      opacity: 0;
      -webkit-transform: rotateX(-179deg);
    }
    10% {
      opacity: 1;
      -webkit-transform: rotateX(0deg);
    }
    20% {
      opacity: 1;
    }
    30% {
      opacity: 1;
    }
    40% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes s2ani {
    0% {
      opacity: 0;
      -moz-transform: rotateX(-179deg);
    }
    10% {
      opacity: 1;
      -moz-transform: rotateX(0deg);
    }
    20% {
      opacity: 1;
    }
    30% {
      opacity: 1;
    }
    40% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes s2ani {
    0% {
      opacity: 0;
      -webkit-transform: rotateX(-179deg);
      -moz-transform: rotateX(-179deg);
      -ms-transform: rotateX(-179deg);
      -o-transform: rotateX(-179deg);
      transform: rotateX(-179deg);
    }
    10% {
      opacity: 1;
      -webkit-transform: rotateX(0deg);
      -moz-transform: rotateX(0deg);
      -ms-transform: rotateX(0deg);
      -o-transform: rotateX(0deg);
      transform: rotateX(0deg);
    }
    20% {
      opacity: 1;
    }
    30% {
      opacity: 1;
    }
    40% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes s3ani {
    0% {
      opacity: 0;
      -webkit-transform: rotateX(-179deg);
    }
    10% {
      opacity: 0;
      -webkit-transform: rotateX(-179deg);
    }
    20% {
      opacity: 1;
      -webkit-transform: rotateX(0deg);
    }
    30% {
      opacity: 1;
    }
    40% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes s3ani {
    0% {
      opacity: 0;
      -moz-transform: rotateX(-179deg);
    }
    10% {
      opacity: 0;
      -moz-transform: rotateX(-179deg);
    }
    20% {
      opacity: 1;
      -moz-transform: rotateX(0deg);
    }
    30% {
      opacity: 1;
    }
    40% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes s3ani {
    0% {
      opacity: 0;
      -webkit-transform: rotateX(-179deg);
      -moz-transform: rotateX(-179deg);
      -ms-transform: rotateX(-179deg);
      -o-transform: rotateX(-179deg);
      transform: rotateX(-179deg);
    }
    10% {
      opacity: 0;
      -webkit-transform: rotateX(-179deg);
      -moz-transform: rotateX(-179deg);
      -ms-transform: rotateX(-179deg);
      -o-transform: rotateX(-179deg);
      transform: rotateX(-179deg);
    }
    20% {
      opacity: 1;
      -webkit-transform: rotateX(0deg);
      -moz-transform: rotateX(0deg);
      -ms-transform: rotateX(0deg);
      -o-transform: rotateX(0deg);
      transform: rotateX(0deg);
    }
    30% {
      opacity: 1;
    }
    40% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes s4ani {
    0% {
      opacity: 0;
      -webkit-transform: rotateX(-179deg);
    }
    10% {
      opacity: 0;
    }
    20% {
      opacity: 0;
      -webkit-transform: rotateX(-179deg);
    }
    30% {
      opacity: 1;
      -webkit-transform: rotateX(0deg);
    }
    40% {
      -webkit-transform: rotateX(0deg);
    }
    50% {
      -webkit-transform: rotateX(90deg);
      opacity: .5;
    }
    60% {
      opacity: .5;
    }
    70% {
      opacity: .5;
    }
    80% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes s4ani {
    0% {
      opacity: 0;
      -moz-transform: rotateX(-179deg);
    }
    10% {
      opacity: 0;
    }
    20% {
      opacity: 0;
      -moz-transform: rotateX(-179deg);
    }
    30% {
      opacity: 1;
      -moz-transform: rotateX(0deg);
    }
    40% {
      -moz-transform: rotateX(0deg);
    }
    50% {
      -moz-transform: rotateX(90deg);
      opacity: .5;
    }
    60% {
      opacity: .5;
    }
    70% {
      opacity: .5;
    }
    80% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes s4ani {
    0% {
      opacity: 0;
      -webkit-transform: rotateX(-179deg);
      -moz-transform: rotateX(-179deg);
      -ms-transform: rotateX(-179deg);
      -o-transform: rotateX(-179deg);
      transform: rotateX(-179deg);
    }
    10% {
      opacity: 0;
    }
    20% {
      opacity: 0;
      -webkit-transform: rotateX(-179deg);
      -moz-transform: rotateX(-179deg);
      -ms-transform: rotateX(-179deg);
      -o-transform: rotateX(-179deg);
      transform: rotateX(-179deg);
    }
    30% {
      opacity: 1;
      -webkit-transform: rotateX(0deg);
      -moz-transform: rotateX(0deg);
      -ms-transform: rotateX(0deg);
      -o-transform: rotateX(0deg);
      transform: rotateX(0deg);
    }
    40% {
      -webkit-transform: rotateX(0deg);
      -moz-transform: rotateX(0deg);
      -ms-transform: rotateX(0deg);
      -o-transform: rotateX(0deg);
      transform: rotateX(0deg);
    }
    50% {
      -webkit-transform: rotateX(90deg);
      -moz-transform: rotateX(90deg);
      -ms-transform: rotateX(90deg);
      -o-transform: rotateX(90deg);
      transform: rotateX(90deg);
      opacity: .5;
    }
    60% {
      opacity: .5;
    }
    70% {
      opacity: .5;
    }
    80% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes s5ani {
    0% {
      opacity: 0;
      -webkit-transform: rotateY(-179deg);
    }
    10% {
      opacity: 0;
      -webkit-transform: rotateY(-179deg);
    }
    20% {
      opacity: 1;
      -webkit-transform: rotateY(0deg);
    }
    30% {
      opacity: 1;
    }
    40% {
      -webkit-transform: rotateY(0deg);
    }
    50% {
      -webkit-transform: rotateY(90deg);
    }
    60% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes s5ani {
    0% {
      opacity: 0;
      -moz-transform: rotateY(-179deg);
    }
    10% {
      opacity: 0;
      -moz-transform: rotateY(-179deg);
    }
    20% {
      opacity: 1;
      -moz-transform: rotateY(0deg);
    }
    30% {
      opacity: 1;
    }
    40% {
      -moz-transform: rotateY(0deg);
    }
    50% {
      -moz-transform: rotateY(90deg);
    }
    60% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes s5ani {
    0% {
      opacity: 0;
      -webkit-transform: rotateY(-179deg);
      -moz-transform: rotateY(-179deg);
      -ms-transform: rotateY(-179deg);
      -o-transform: rotateY(-179deg);
      transform: rotateY(-179deg);
    }
    10% {
      opacity: 0;
      -webkit-transform: rotateY(-179deg);
      -moz-transform: rotateY(-179deg);
      -ms-transform: rotateY(-179deg);
      -o-transform: rotateY(-179deg);
      transform: rotateY(-179deg);
    }
    20% {
      opacity: 1;
      -webkit-transform: rotateY(0deg);
      -moz-transform: rotateY(0deg);
      -ms-transform: rotateY(0deg);
      -o-transform: rotateY(0deg);
      transform: rotateY(0deg);
    }
    30% {
      opacity: 1;
    }
    40% {
      -webkit-transform: rotateY(0deg);
      -moz-transform: rotateY(0deg);
      -ms-transform: rotateY(0deg);
      -o-transform: rotateY(0deg);
      transform: rotateY(0deg);
    }
    50% {
      -webkit-transform: rotateY(90deg);
      -moz-transform: rotateY(90deg);
      -ms-transform: rotateY(90deg);
      -o-transform: rotateY(90deg);
      transform: rotateY(90deg);
    }
    60% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes s6ani {
    0% {
      opacity: 0;
      -webkit-transform: rotateY(179deg);
    }
    10% {
      opacity: 0;
    }
    20% {
      opacity: 0;
      -webkit-transform: rotateY(179deg);
    }
    30% {
      opacity: 1;
      -webkit-transform: rotateY(0deg);
    }
    40% {
      -webkit-transform: rotateY(0deg);
    }
    50% {
      -webkit-transform: rotateY(-90deg);
    }
    60% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes s6ani {
    0% {
      opacity: 0;
      -moz-transform: rotateY(179deg);
    }
    10% {
      opacity: 0;
    }
    20% {
      opacity: 0;
      -moz-transform: rotateY(179deg);
    }
    30% {
      opacity: 1;
      -moz-transform: rotateY(0deg);
    }
    40% {
      -moz-transform: rotateY(0deg);
    }
    50% {
      -moz-transform: rotateY(-90deg);
    }
    60% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes s6ani {
    0% {
      opacity: 0;
      -webkit-transform: rotateY(179deg);
      -moz-transform: rotateY(179deg);
      -ms-transform: rotateY(179deg);
      -o-transform: rotateY(179deg);
      transform: rotateY(179deg);
    }
    10% {
      opacity: 0;
    }
    20% {
      opacity: 0;
      -webkit-transform: rotateY(179deg);
      -moz-transform: rotateY(179deg);
      -ms-transform: rotateY(179deg);
      -o-transform: rotateY(179deg);
      transform: rotateY(179deg);
    }
    30% {
      opacity: 1;
      -webkit-transform: rotateY(0deg);
      -moz-transform: rotateY(0deg);
      -ms-transform: rotateY(0deg);
      -o-transform: rotateY(0deg);
      transform: rotateY(0deg);
    }
    40% {
      -webkit-transform: rotateY(0deg);
      -moz-transform: rotateY(0deg);
      -ms-transform: rotateY(0deg);
      -o-transform: rotateY(0deg);
      transform: rotateY(0deg);
    }
    50% {
      -webkit-transform: rotateY(-90deg);
      -moz-transform: rotateY(-90deg);
      -ms-transform: rotateY(-90deg);
      -o-transform: rotateY(-90deg);
      transform: rotateY(-90deg);
    }
    60% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  body {
    background: black;
    color: #6BD6DD;
  }
  
//   .container {
//     position: relative;
//     width: 100px;
//     height: 100px;
//     margin: 100px auto 0;
//     -webkit-perspective: 300px;
//     -moz-perspective: 300px;
//     perspective: 300px;
//     -webkit-transform-style: preserve-3d;
//     -moz-transform-style: preserve-3d;
//     -ms-transform-style: preserve-3d;
//     -o-transform-style: preserve-3d;
//     transform-style: preserve-3d;
//   }
  
  .cube {
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
  
  .side {
    width: 20px;
    height: 20px;
    background: #6BD6DD;
    position: absolute;
  }
  
  .s1 {
    top: 0;
    left: 40px;
    -webkit-transform-origin: 50% 100%;
    -moz-transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    -o-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-animation: s1ani 10s forwards infinite;
    -moz-animation: s1ani 10s forwards infinite;
    animation: s1ani 10s forwards infinite;
  }
  
  .s2 {
    top: 20px;
    left: 40px;
    -webkit-transform-origin: 50% 0%;
    -moz-transform-origin: 50% 0%;
    -ms-transform-origin: 50% 0%;
    -o-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-animation: s2ani 10s forwards infinite;
    -moz-animation: s2ani 10s forwards infinite;
    animation: s2ani 10s forwards infinite;
  }
  
  .s3 {
    top: 40px;
    left: 40px;
    -webkit-transform-origin: 50% 0%;
    -moz-transform-origin: 50% 0%;
    -ms-transform-origin: 50% 0%;
    -o-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-animation: s3ani 10s forwards infinite;
    -moz-animation: s3ani 10s forwards infinite;
    animation: s3ani 10s forwards infinite;
  }
  
  .s4 {
    top: 60px;
    left: 40px;
    -webkit-transform-origin: 50% 0%;
    -moz-transform-origin: 50% 0%;
    -ms-transform-origin: 50% 0%;
    -o-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-animation: s4ani 10s forwards infinite;
    -moz-animation: s4ani 10s forwards infinite;
    animation: s4ani 10s forwards infinite;
  }
  
  .s5 {
    left: 20px;
    top: 20px;
    -webkit-transform-origin: 100% 50%;
    -moz-transform-origin: 100% 50%;
    -ms-transform-origin: 100% 50%;
    -o-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-animation: s5ani 10s forwards infinite;
    -moz-animation: s5ani 10s forwards infinite;
    animation: s5ani 10s forwards infinite;
  }
  
  .s6 {
    left: 60px;
    top: 40px;
    -webkit-transform-origin: 0% 50%;
    -moz-transform-origin: 0% 50%;
    -ms-transform-origin: 0% 50%;
    -o-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    -webkit-animation: s6ani 10s forwards infinite;
    -moz-animation: s6ani 10s forwards infinite;
    animation: s6ani 10s forwards infinite;
  }
  
  @-webkit-keyframes cube {
    0% {
      -webkit-transform: rotateX(0deg);
    }
    30% {
      -webkit-transform: rotateX(0deg);
    }
    40% {
      -webkit-transform: rotateX(45deg) rotateY(0deg) rotateZ(45deg);
      /* CUBE ROTATES BACK */
    }
    60% {
      -webkit-transform: rotateX(60deg) rotateY(0deg) rotateZ(45deg);
      /* ARMS FOLD UP */
    }
    65% {
      -webkit-transform: rotateX(60deg) rotate(45deg) rotateZ(180deg);
      /* SPIN REVEALS CYAN RIGHT, RED LEFT (s2) */
    }
    70% {
      -webkit-transform: rotateX(60deg) rotate(45deg) rotateZ(180deg);
    }
    75% {
      -webkit-transform: rotateX(60deg) rotate(45deg) rotateZ(360deg);
      /* SPIN REVEALS MAGENTA RIGHT, YELLOW LEFT (s4) */
    }
    80% {
      -webkit-transform: rotateX(60deg) rotate(45deg) rotateZ(360deg);
    }
    90% {
      -webkit-transform: rotateX(0deg) rotate(0deg) rotateZ(0deg);
      /* FADE TO BLACK */
    }
  }
  @-moz-keyframes cube {
    0% {
      -moz-transform: rotateX(0deg);
    }
    30% {
      -moz-transform: rotateX(0deg);
    }
    40% {
      -moz-transform: rotateX(45deg) rotateY(0deg) rotateZ(45deg);
      /* CUBE ROTATES BACK */
    }
    60% {
      -moz-transform: rotateX(60deg) rotateY(0deg) rotateZ(45deg);
      /* ARMS FOLD UP */
    }
    65% {
      -moz-transform: rotateX(60deg) rotate(45deg) rotateZ(180deg);
      /* SPIN REVEALS CYAN RIGHT, RED LEFT (s2) */
    }
    70% {
      -moz-transform: rotateX(60deg) rotate(45deg) rotateZ(180deg);
    }
    75% {
      -moz-transform: rotateX(60deg) rotate(45deg) rotateZ(360deg);
      /* SPIN REVEALS MAGENTA RIGHT, YELLOW LEFT (s4) */
    }
    80% {
      -moz-transform: rotateX(60deg) rotate(45deg) rotateZ(360deg);
    }
    90% {
      -moz-transform: rotateX(0deg) rotate(0deg) rotateZ(0deg);
      /* FADE TO BLACK */
    }
  }
  @keyframes cube {
    0% {
      -webkit-transform: rotateX(0deg);
      -moz-transform: rotateX(0deg);
      -ms-transform: rotateX(0deg);
      -o-transform: rotateX(0deg);
      transform: rotateX(0deg);
    }
    30% {
      -webkit-transform: rotateX(0deg);
      -moz-transform: rotateX(0deg);
      -ms-transform: rotateX(0deg);
      -o-transform: rotateX(0deg);
      transform: rotateX(0deg);
    }
    40% {
      -webkit-transform: rotateX(45deg) rotateY(0deg) rotateZ(45deg);
      -moz-transform: rotateX(45deg) rotateY(0deg) rotateZ(45deg);
      -ms-transform: rotateX(45deg) rotateY(0deg) rotateZ(45deg);
      -o-transform: rotateX(45deg) rotateY(0deg) rotateZ(45deg);
      transform: rotateX(45deg) rotateY(0deg) rotateZ(45deg);
      /* CUBE ROTATES BACK */
    }
    60% {
      -webkit-transform: rotateX(60deg) rotateY(0deg) rotateZ(45deg);
      -moz-transform: rotateX(60deg) rotateY(0deg) rotateZ(45deg);
      -ms-transform: rotateX(60deg) rotateY(0deg) rotateZ(45deg);
      -o-transform: rotateX(60deg) rotateY(0deg) rotateZ(45deg);
      transform: rotateX(60deg) rotateY(0deg) rotateZ(45deg);
      /* ARMS FOLD UP */
    }
    65% {
      -webkit-transform: rotateX(60deg) rotate(45deg) rotateZ(180deg);
      -moz-transform: rotateX(60deg) rotate(45deg) rotateZ(180deg);
      -ms-transform: rotateX(60deg) rotate(45deg) rotateZ(180deg);
      -o-transform: rotateX(60deg) rotate(45deg) rotateZ(180deg);
      transform: rotateX(60deg) rotate(45deg) rotateZ(180deg);
      /* SPIN REVEALS CYAN RIGHT, RED LEFT (s2) */
    }
    70% {
      -webkit-transform: rotateX(60deg) rotate(45deg) rotateZ(180deg);
      -moz-transform: rotateX(60deg) rotate(45deg) rotateZ(180deg);
      -ms-transform: rotateX(60deg) rotate(45deg) rotateZ(180deg);
      -o-transform: rotateX(60deg) rotate(45deg) rotateZ(180deg);
      transform: rotateX(60deg) rotate(45deg) rotateZ(180deg);
    }
    75% {
      -webkit-transform: rotateX(60deg) rotate(45deg) rotateZ(360deg);
      -moz-transform: rotateX(60deg) rotate(45deg) rotateZ(360deg);
      -ms-transform: rotateX(60deg) rotate(45deg) rotateZ(360deg);
      -o-transform: rotateX(60deg) rotate(45deg) rotateZ(360deg);
      transform: rotateX(60deg) rotate(45deg) rotateZ(360deg);
      /* SPIN REVEALS MAGENTA RIGHT, YELLOW LEFT (s4) */
    }
    80% {
      -webkit-transform: rotateX(60deg) rotate(45deg) rotateZ(360deg);
      -moz-transform: rotateX(60deg) rotate(45deg) rotateZ(360deg);
      -ms-transform: rotateX(60deg) rotate(45deg) rotateZ(360deg);
      -o-transform: rotateX(60deg) rotate(45deg) rotateZ(360deg);
      transform: rotateX(60deg) rotate(45deg) rotateZ(360deg);
    }
    90% {
      -webkit-transform: rotateX(0deg) rotate(0deg) rotateZ(0deg);
      -moz-transform: rotateX(0deg) rotate(0deg) rotateZ(0deg);
      -ms-transform: rotateX(0deg) rotate(0deg) rotateZ(0deg);
      -o-transform: rotateX(0deg) rotate(0deg) rotateZ(0deg);
      transform: rotateX(0deg) rotate(0deg) rotateZ(0deg);
      /* FADE TO BLACK */
    }
  }
  @-webkit-keyframes s1ani {
    0% {
      opacity: 1;
      -webkit-transform: translateY(0px);
      background: #6BD6DD;
    }
    40% {
      -webkit-transform: rotateX(0deg);
      background: #6BD6DD;
    }
    50% {
      -webkit-transform: rotateX(-90deg);
      background: #6BD6DD;
    }
    90% {
      -webkit-transform: rotateX(-90deg);
    }
  }
  @-moz-keyframes s1ani {
    0% {
      opacity: 1;
      -moz-transform: translateY(0px);
      background: #6BD6DD;
    }
    40% {
      -moz-transform: rotateX(0deg);
      background: #6BD6DD;
    }
    50% {
      -moz-transform: rotateX(-90deg);
      background: #6BD6DD;
    }
    90% {
      -moz-transform: rotateX(-90deg);
    }
  }
  @keyframes s1ani {
    0% {
      opacity: 1;
      -webkit-transform: translateY(0px);
      -moz-transform: translateY(0px);
      -ms-transform: translateY(0px);
      -o-transform: translateY(0px);
      transform: translateY(0px);
      background: #6BD6DD;
    }
    40% {
      -webkit-transform: rotateX(0deg);
      -moz-transform: rotateX(0deg);
      -ms-transform: rotateX(0deg);
      -o-transform: rotateX(0deg);
      transform: rotateX(0deg);
      background: #6BD6DD;
    }
    50% {
      -webkit-transform: rotateX(-90deg);
      -moz-transform: rotateX(-90deg);
      -ms-transform: rotateX(-90deg);
      -o-transform: rotateX(-90deg);
      transform: rotateX(-90deg);
      background: #6BD6DD;
    }
    90% {
      -webkit-transform: rotateX(-90deg);
      -moz-transform: rotateX(-90deg);
      -ms-transform: rotateX(-90deg);
      -o-transform: rotateX(-90deg);
      transform: rotateX(-90deg);
    }
  }
  @-webkit-keyframes s2ani {
    0% {
      opacity: 0;
      -webkit-transform: rotateX(-179deg);
    }
    10% {
      opacity: 1;
      -webkit-transform: rotateX(0deg);
    }
    40% {
      background: #6BD6DD;
    }
    45% {
      background: rgb(87, 184, 190);
    }
    65% {
      opacity: 1;
      background: rgb(87, 184, 190);
      /* DARKEST VISIBLE */
    }
    80% {
      background: rgb(87, 184, 190);
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @-moz-keyframes s2ani {
    0% {
      opacity: 0;
      -moz-transform: rotateX(-179deg);
    }
    10% {
      opacity: 1;
      -moz-transform: rotateX(0deg);
    }
    40% {
      background: #6BD6DD;
    }
    45% {
      background: rgb(87, 184, 190);
    }
    65% {
      opacity: 1;
      background: rgb(87, 184, 190);
      /* DARKEST VISIBLE */
    }
    80% {
      background: rgb(87, 184, 190);
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes s2ani {
    0% {
      opacity: 0;
      -webkit-transform: rotateX(-179deg);
      -moz-transform: rotateX(-179deg);
      -ms-transform: rotateX(-179deg);
      -o-transform: rotateX(-179deg);
      transform: rotateX(-179deg);
    }
    10% {
      opacity: 1;
      -webkit-transform: rotateX(0deg);
      -moz-transform: rotateX(0deg);
      -ms-transform: rotateX(0deg);
      -o-transform: rotateX(0deg);
      transform: rotateX(0deg);
    }
    40% {
      background: #6BD6DD;
    }
    45% {
      background: rgb(87, 184, 190);
    }
    65% {
      opacity: 1;
      background: rgb(87, 184, 190);
      /* DARKEST VISIBLE */
    }
    80% {
      background: rgb(87, 184, 190);
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @-webkit-keyframes s3ani {
    0% {
      opacity: 0;
      -webkit-transform: rotateX(-179deg);
    }
    10% {
      opacity: 0;
      -webkit-transform: rotateX(-179deg);
    }
    20% {
      opacity: 1;
      -webkit-transform: rotateX(0deg);
    }
    40% {
      background: #6BD6DD;
    }
    45% {
      background: #26878D;
      /* DARKEST VISIBLE */
    }
    90% {
      opacity: 1;
      -webkit-transform: rotateX(0deg);
    }
    100% {
      opacity: 0;
    }
  }
  @-moz-keyframes s3ani {
    0% {
      opacity: 0;
      -moz-transform: rotateX(-179deg);
    }
    10% {
      opacity: 0;
      -moz-transform: rotateX(-179deg);
    }
    20% {
      opacity: 1;
      -moz-transform: rotateX(0deg);
    }
    40% {
      background: #6BD6DD;
    }
    45% {
      background: #26878D;
      /* DARKEST VISIBLE */
    }
    90% {
      opacity: 1;
      -moz-transform: rotateX(0deg);
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes s3ani {
    0% {
      opacity: 0;
      -webkit-transform: rotateX(-179deg);
      -moz-transform: rotateX(-179deg);
      -ms-transform: rotateX(-179deg);
      -o-transform: rotateX(-179deg);
      transform: rotateX(-179deg);
    }
    10% {
      opacity: 0;
      -webkit-transform: rotateX(-179deg);
      -moz-transform: rotateX(-179deg);
      -ms-transform: rotateX(-179deg);
      -o-transform: rotateX(-179deg);
      transform: rotateX(-179deg);
    }
    20% {
      opacity: 1;
      -webkit-transform: rotateX(0deg);
      -moz-transform: rotateX(0deg);
      -ms-transform: rotateX(0deg);
      -o-transform: rotateX(0deg);
      transform: rotateX(0deg);
    }
    40% {
      background: #6BD6DD;
    }
    45% {
      background: #26878D;
      /* DARKEST VISIBLE */
    }
    90% {
      opacity: 1;
      -webkit-transform: rotateX(0deg);
      -moz-transform: rotateX(0deg);
      -ms-transform: rotateX(0deg);
      -o-transform: rotateX(0deg);
      transform: rotateX(0deg);
    }
    100% {
      opacity: 0;
    }
  }
  @-webkit-keyframes s4ani {
    0% {
      opacity: 0;
      -webkit-transform: rotateX(-179deg);
    }
    10% {
      opacity: 0;
    }
    20% {
      opacity: 0;
      -webkit-transform: rotateX(-179deg);
    }
    30% {
      opacity: 1;
      -webkit-transform: rotateX(0deg);
    }
    40% {
      -webkit-transform: rotateX(0deg);
      background: #6BD6DD;
    }
    50% {
      -webkit-transform: rotateX(90deg);
      background: rgb(87, 184, 190);
    }
    80% {
      background: rgb(87, 184, 190);
    }
    90% {
      opacity: 1;
      -webkit-transform: rotateX(90deg);
    }
    100% {
      opacity: 0;
    }
  }
  @-moz-keyframes s4ani {
    0% {
      opacity: 0;
      -moz-transform: rotateX(-179deg);
    }
    10% {
      opacity: 0;
    }
    20% {
      opacity: 0;
      -moz-transform: rotateX(-179deg);
    }
    30% {
      opacity: 1;
      -moz-transform: rotateX(0deg);
    }
    40% {
      -moz-transform: rotateX(0deg);
      background: #6BD6DD;
    }
    50% {
      -moz-transform: rotateX(90deg);
      background: rgb(87, 184, 190);
    }
    80% {
      background: rgb(87, 184, 190);
    }
    90% {
      opacity: 1;
      -moz-transform: rotateX(90deg);
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes s4ani {
    0% {
      opacity: 0;
      -webkit-transform: rotateX(-179deg);
      -moz-transform: rotateX(-179deg);
      -ms-transform: rotateX(-179deg);
      -o-transform: rotateX(-179deg);
      transform: rotateX(-179deg);
    }
    10% {
      opacity: 0;
    }
    20% {
      opacity: 0;
      -webkit-transform: rotateX(-179deg);
      -moz-transform: rotateX(-179deg);
      -ms-transform: rotateX(-179deg);
      -o-transform: rotateX(-179deg);
      transform: rotateX(-179deg);
    }
    30% {
      opacity: 1;
      -webkit-transform: rotateX(0deg);
      -moz-transform: rotateX(0deg);
      -ms-transform: rotateX(0deg);
      -o-transform: rotateX(0deg);
      transform: rotateX(0deg);
    }
    40% {
      -webkit-transform: rotateX(0deg);
      -moz-transform: rotateX(0deg);
      -ms-transform: rotateX(0deg);
      -o-transform: rotateX(0deg);
      transform: rotateX(0deg);
      background: #6BD6DD;
    }
    50% {
      -webkit-transform: rotateX(90deg);
      -moz-transform: rotateX(90deg);
      -ms-transform: rotateX(90deg);
      -o-transform: rotateX(90deg);
      transform: rotateX(90deg);
      background: rgb(87, 184, 190);
    }
    80% {
      background: rgb(87, 184, 190);
    }
    90% {
      opacity: 1;
      -webkit-transform: rotateX(90deg);
      -moz-transform: rotateX(90deg);
      -ms-transform: rotateX(90deg);
      -o-transform: rotateX(90deg);
      transform: rotateX(90deg);
    }
    100% {
      opacity: 0;
    }
  }
  @-webkit-keyframes s5ani {
    0% {
      opacity: 0;
      -webkit-transform: rotateY(-179deg);
    }
    10% {
      opacity: 0;
      -webkit-transform: rotateY(-179deg);
    }
    20% {
      opacity: 1;
      background: #6BD6DD;
      -webkit-transform: rotateY(0deg);
    }
    40% {
      -webkit-transform: rotateY(0deg);
    }
    50% {
      -webkit-transform: rotateY(90deg);
    }
    55% {
      background: #6BD6DD;
    }
    60% {
      background: #c8c8c8;
    }
    90% {
      -webkit-transform: rotateY(90deg);
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @-moz-keyframes s5ani {
    0% {
      opacity: 0;
      -moz-transform: rotateY(-179deg);
    }
    10% {
      opacity: 0;
      -moz-transform: rotateY(-179deg);
    }
    20% {
      opacity: 1;
      background: #6BD6DD;
      -moz-transform: rotateY(0deg);
    }
    40% {
      -moz-transform: rotateY(0deg);
    }
    50% {
      -moz-transform: rotateY(90deg);
    }
    55% {
      background: #6BD6DD;
    }
    60% {
      background: #c8c8c8;
    }
    90% {
      -moz-transform: rotateY(90deg);
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes s5ani {
    0% {
      opacity: 0;
      -webkit-transform: rotateY(-179deg);
      -moz-transform: rotateY(-179deg);
      -ms-transform: rotateY(-179deg);
      -o-transform: rotateY(-179deg);
      transform: rotateY(-179deg);
    }
    10% {
      opacity: 0;
      -webkit-transform: rotateY(-179deg);
      -moz-transform: rotateY(-179deg);
      -ms-transform: rotateY(-179deg);
      -o-transform: rotateY(-179deg);
      transform: rotateY(-179deg);
    }
    20% {
      opacity: 1;
      background: #6BD6DD;
      -webkit-transform: rotateY(0deg);
      -moz-transform: rotateY(0deg);
      -ms-transform: rotateY(0deg);
      -o-transform: rotateY(0deg);
      transform: rotateY(0deg);
    }
    40% {
      -webkit-transform: rotateY(0deg);
      -moz-transform: rotateY(0deg);
      -ms-transform: rotateY(0deg);
      -o-transform: rotateY(0deg);
      transform: rotateY(0deg);
    }
    50% {
      -webkit-transform: rotateY(90deg);
      -moz-transform: rotateY(90deg);
      -ms-transform: rotateY(90deg);
      -o-transform: rotateY(90deg);
      transform: rotateY(90deg);
    }
    55% {
      background: #6BD6DD;
    }
    60% {
      background: #c8c8c8;
    }
    90% {
      -webkit-transform: rotateY(90deg);
      -moz-transform: rotateY(90deg);
      -ms-transform: rotateY(90deg);
      -o-transform: rotateY(90deg);
      transform: rotateY(90deg);
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @-webkit-keyframes s6ani {
    0% {
      opacity: 0;
      -webkit-transform: rotateY(179deg);
    }
    20% {
      opacity: 0;
      -webkit-transform: rotateY(179deg);
    }
    30% {
      opacity: 1;
      -webkit-transform: rotateY(0deg);
    }
    40% {
      -webkit-transform: rotateY(0deg);
    }
    50% {
      -webkit-transform: rotateY(-90deg);
      background: #6BD6DD;
    }
    60% {
      background: #c8c8c8;
    }
    80% {
      background: #c8c8c8;
    }
    90% {
      opacity: 1;
      -webkit-transform: rotateY(-90deg);
    }
    100% {
      opacity: 0;
    }
  }
  @-moz-keyframes s6ani {
    0% {
      opacity: 0;
      -moz-transform: rotateY(179deg);
    }
    20% {
      opacity: 0;
      -moz-transform: rotateY(179deg);
    }
    30% {
      opacity: 1;
      -moz-transform: rotateY(0deg);
    }
    40% {
      -moz-transform: rotateY(0deg);
    }
    50% {
      -moz-transform: rotateY(-90deg);
      background: #6BD6DD;
    }
    60% {
      background: #c8c8c8;
    }
    80% {
      background: #c8c8c8;
    }
    90% {
      opacity: 1;
      -moz-transform: rotateY(-90deg);
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes s6ani {
    0% {
      opacity: 0;
      -webkit-transform: rotateY(179deg);
      -moz-transform: rotateY(179deg);
      -ms-transform: rotateY(179deg);
      -o-transform: rotateY(179deg);
      transform: rotateY(179deg);
    }
    20% {
      opacity: 0;
      -webkit-transform: rotateY(179deg);
      -moz-transform: rotateY(179deg);
      -ms-transform: rotateY(179deg);
      -o-transform: rotateY(179deg);
      transform: rotateY(179deg);
    }
    30% {
      opacity: 1;
      -webkit-transform: rotateY(0deg);
      -moz-transform: rotateY(0deg);
      -ms-transform: rotateY(0deg);
      -o-transform: rotateY(0deg);
      transform: rotateY(0deg);
    }
    40% {
      -webkit-transform: rotateY(0deg);
      -moz-transform: rotateY(0deg);
      -ms-transform: rotateY(0deg);
      -o-transform: rotateY(0deg);
      transform: rotateY(0deg);
    }
    50% {
      -webkit-transform: rotateY(-90deg);
      -moz-transform: rotateY(-90deg);
      -ms-transform: rotateY(-90deg);
      -o-transform: rotateY(-90deg);
      transform: rotateY(-90deg);
      background: #6BD6DD;
    }
    60% {
      background: #c8c8c8;
    }
    80% {
      background: #c8c8c8;
    }
    90% {
      opacity: 1;
      -webkit-transform: rotateY(-90deg);
      -moz-transform: rotateY(-90deg);
      -ms-transform: rotateY(-90deg);
      -o-transform: rotateY(-90deg);
      transform: rotateY(-90deg);
    }
    100% {
      opacity: 0;
    }
  }
  @-webkit-keyframes half-fold {
    0% {
      -webkit-transform: rotateX(0deg);
    }
    50% {
      -webkit-transform: rotateX(0deg);
    }
    60% {
      -webkit-transform: rotateX(-90deg);
    }
    90% {
      -webkit-transform: rotateX(-90deg);
    }
  }
  @-moz-keyframes half-fold {
    0% {
      -moz-transform: rotateX(0deg);
    }
    50% {
      -moz-transform: rotateX(0deg);
    }
    60% {
      -moz-transform: rotateX(-90deg);
    }
    90% {
      -moz-transform: rotateX(-90deg);
    }
  }
  @keyframes half-fold {
    0% {
      -webkit-transform: rotateX(0deg);
      -moz-transform: rotateX(0deg);
      -ms-transform: rotateX(0deg);
      -o-transform: rotateX(0deg);
      transform: rotateX(0deg);
    }
    50% {
      -webkit-transform: rotateX(0deg);
      -moz-transform: rotateX(0deg);
      -ms-transform: rotateX(0deg);
      -o-transform: rotateX(0deg);
      transform: rotateX(0deg);
    }
    60% {
      -webkit-transform: rotateX(-90deg);
      -moz-transform: rotateX(-90deg);
      -ms-transform: rotateX(-90deg);
      -o-transform: rotateX(-90deg);
      transform: rotateX(-90deg);
    }
    90% {
      -webkit-transform: rotateX(-90deg);
      -moz-transform: rotateX(-90deg);
      -ms-transform: rotateX(-90deg);
      -o-transform: rotateX(-90deg);
      transform: rotateX(-90deg);
    }
  }
  