#accordion {
    text-align: center;

    ul {
        list-style: none;
        padding: 0 1em;
        margin: 0;
        display: block;
        max-height: 100vh;
    }

    ul>li {
        padding: 0;
        margin: .75rem 1em;
        font-size: 24px;
        display: block;
    }

    ul>li>a {
        position: relative;
        display: inline;
        cursor: pointer;
        transition: color 0.4s ease;

        font-size: 30px;
        font-family: "Garamond-Bold";
        color: rgb(185, 184, 185);
    }

    ul>li>a:hover {
        color: rgb(255, 255, 255);
    }

    ul>li>a:hover:after {
        width: 100%;
    }

    ul>li>a:after {
        content: '';
        position: absolute;
        z-index: 1;
        bottom: -0.15em;
        left: 0;
        width: 0;
        height: 2px;
        background: #e5e5e5;
        transition: width 0.4s ease;
    }

    .card {
        padding: 0;
        margin: 0;
        background: transparent;

        &-link {
            transition: color 0.4s ease;
            font-size: 30px;
            font-family: "Garamond-Bold";
            color: rgb(185, 184, 185);

            &:hover {
                color: white;
            }
        }

        &-header {
            margin: 0;
            padding: 0;
        }

        &-body {
            padding: 0;
            margin: 0;

            a {
                font-size: 14px;
                font-family: "Montserrat-Regular";
                color: rgb(185, 184, 185);
                text-transform: uppercase;

                &:hover {
                    color: white;
                }
            }
        }
    }
}

.my-menu-icon {
    position: fixed;
    top: 10px;
    right: 17px;
    z-index: 9999;
}

$transition: all 0.4s cubic-bezier(0.970, 0.000, 0.395, 0.970);
$menu-inactive-color: #9F7F5E;
$menu-active-color: #FFF;

.menu-icon {
    width: 30px;
    height: 30px;
    border: 1px solid $my-yellow;
    cursor: pointer;
    overflow: hidden;
    transition: $transition;
    transform: rotate(45deg);

    &:hover {
        border-color: $menu-active-color;
        transform: rotate(135deg);

        .hamburger-wrap {
            transform: rotate(-135deg);

            span {
                transform: translateX(32px) translateY(-3px);
            }
        }
    }
}

.hamburger-wrap {
    width: 24px;
    height: 24px;
    padding-left: 4px;
    padding-top: 6px;
    transition: $transition;
    transform: rotate(-45deg);

    span {
        width: 18px;
        height: 2px;
        display: block;
        margin-top: 3px;
        background-color: $my-yellow;
        transition: $transition;

        &:nth-of-type(1) {
            transition-delay: 0s;
        }

        &:nth-of-type(2) {
            transition-delay: 0.05s;
        }

        &:nth-of-type(3) {
            transition-delay: 0.1s;
        }

        &:before {
            width: 18px;
            height: 2px;
            margin-top: 3px;
            display: block;
            content: '';
            background-color: $menu-active-color;
            transform: translateX(-35px);
        }
    }
}

body {
    margin: 0;
    overflow: hidden;
    background-repeat: no-repeat;
}

// #canvas {
//     position: fixed;
//     z-index: 99999999999999999999999;
// }