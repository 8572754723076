.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    z-index: 999999999;

    border: 0;
    border-radius: 0;

    &-dialog {
        position: fixed;
        margin: 0;
        width: 100%;
        height: 100%;
        padding: 0;
        width: 100vw;
        height: 100vh;

        border: 0;
        border-radius: 0;
    }

    &-content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 0;
        box-shadow: none;
        width: 100vw;
        height: 100vh;

        border: 0;
        border-radius: 0;

        background-image: url('../img/menu_bg.png');
        @extend %bgcover;
        transition: all 0.4s ease;
        // overflow: auto;
    }

    &-header {
        height: 50px;
        border: 0;
        border-radius: 0;

        .close {
            text-shadow: unset !important;
            font-weight: 100 !important;
            color: white;
            opacity: 1;
            position: absolute;
            right: 30px;
            top: 20px;
            transition: all 0.4s ease;

            i {
                color: white;
                font-size: 36px;
            }

            &:hover {
                transform: rotate(90deg);
            }
        }
    }

    &-body {
        border: 0;
        margin: 0;
        border-radius: 0;
    }

    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 10px;
        background: #f1f3f5;
        border-left: 1px solid darken(#f1f3f5, 10%);
    }

    ::-webkit-scrollbar-thumb {
        background: darken(#f1f3f5, 20%);
    }

    .section-content > div {
        margin-bottom: 40px;
    }

    .my-btn__one {
        margin-top: 0px !important;
    }

    .section-title {
        padding: 5px;
    }
}