// .my-btn {
//     background-color: transparent;
//     border: 0;
//     color: white;
//     @extend %tr03;

//     &__one {
//         font-size: 14px;
//         font-family: "Montserrat-Bold";
//         color: rgb(255, 255, 255);
//         text-transform: uppercase;
//         background-color: $my-yellow;
//         padding: 12px 30px;

//         &:hover {
//             background-color: rgb(255, 255, 255);
//             color: $my-yellow;
//         }
//     }
// }

//colors
// $red: #E1332D;
// $white: #fff;

//default button
.my-btn {
    color: $my-yellow;
    cursor: pointer;
    // display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 45px;
    margin: 0 0 2em;
    max-width: 160px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    background-color: transparent;
    width: 100%;

    //   @media(min-width: 400px) {
    //     display: inline-block;
    //     margin-right: 2.5em;

    //     &:nth-of-type(even) {
    //       margin-right: 0;
    //     }
    //   }

    @media(min-width: 600px) {

        margin: 0 1em 2em;

        //     &:nth-of-type(even) {
        //       margin-right: 2.5em;
        //     }

        //     &:nth-of-type(5) {
        //       margin-right: 0;
        //     }

    }

    &:hover {
        text-decoration: none;
    }

}

/////////////////////////////
//button-4 
///////////////////////////
.my-btn__one {
    border: 1px solid;
    overflow: hidden;
    position: relative;

    span {
        z-index: 20;
    }

    &:after {
        background: $my-yellow;
        content: "";
        height: 155px;
        left: -75px;
        opacity: .2;
        position: absolute;
        top: -50px;
        transform: rotate(35deg);
        transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        width: 50px;
        z-index: 10;
    }
}

.my-btn__one:hover {

    &:after {
        left: 120%;
        transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    }
}