// Section 1
#section1-left {
    text-align: center;
    background-image: url('../img/image.png');
    @extend %bgcover;
}

#section1-right {
    text-align: center;
    background-image: url('../img/image1.png');
    @extend %bgcover;
}

// Section 2
#section2-left {
    text-align: center;
    background-image: url('../img/image2.png');
    @extend %bgcover;
}

#section2-right {
    text-align: center;
    background-image: url('../img/image.png');
    @extend %bgcover;
}

// Section 3
#section3-left {
    text-align: center;
    background-image: url('../img/image.png');
    @extend %bgcover;
}

#section3-right {
    text-align: center;
    background-image: url('../img/image3.png');
    @extend %bgcover;
}


// Section 4
#section4-left {
    text-align: center;
    background-image: url('../img/image4.png');
    @extend %bgcover;
}

#section4-right {
    text-align: center;
    background-image: url('../img/image.png');
    @extend %bgcover;
}


// Section 5
#section5-left {
    text-align: center;
    background-image: url('../img/image.png');
    @extend %bgcover;
}

#section5-right {
    text-align: center;
    background-image: url('../img/image5.png');
    @extend %bgcover;
}


// Section 6
#section6-left {
    text-align: center;
    background-image: url('../img/image6.png');
    @extend %bgcover;

    @media all and (min-width: 1280px) {
        background-position: -100px center;
    }

    @media all and (min-width: 1360px) {
        background-position: center;
    }
}

#section6-right {
    text-align: center;
    background-image: url('../img/image.png');
    @extend %bgcover;
}


// Section 7
#section7-left {
    text-align: center;
    background-image: url('../img/image.png');
    @extend %bgcover;
}

#section7-right {
    text-align: center;
    background-image: url('../img/image7.png');
    @extend %bgcover;
}


// Section 8
#section8-left {
    text-align: center;
    background-image: url('../img/image8.png');
    @extend %bgcover;
    background-position: left center;

    @media all and (min-width: 1280px) {
        background-position: -100px center;
    }

    @media all and (min-width: 1360px) {
        background-position: left center;
    }
}

#section8-right {
    text-align: center;
    background-image: url('../img/image.png');
    @extend %bgcover;
}


// Section 9
#section9-left {
    text-align: center;
    background-image: url('../img/image.png');
    @extend %bgcover;
}

#section9-right {
    text-align: center;
    background-image: url('../img/image9.png');
    @extend %bgcover;
}

#section1-right.hideOverflow {
    overflow: hidden;
}


/* Fixed header and footer.
	* --------------------------------------- */
#header {
    position: fixed;
    height: 50px;
    display: block;
    width: 100%;
    background: transparent;
    z-index: 9;
    text-align: center;
    color: #f2f2f2;
    padding: 20px 0 0 0;


    display: flex;
    justify-content: flex-end;
    align-items: center;
}

#header {
    top: 0px;
    right: 17px;
    width: 50px;
}


#multiscroll-nav li span {
    background: transparent;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(255, 255, 255);
    border-radius: 50%;
}

#multiscroll-nav li .active span {
    background: transparent;
    background-color: $my-yellow;
    border-color: $my-yellow;
}

#multiscroll-nav.right {
    right: 25px;
}