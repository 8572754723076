.my-form {
    input, select, textarea {
        background-color: #fff;
        padding: 10px 20px;
        border: 0;
        font-size: 14px;
        font-family: "Montserrat-Bold";
        color: rgb(34, 34, 34);
        width: 350px;
        line-height: 2.2;
        margin: 5px 0;
        height: 50px;
         
        &::placeholder {
            text-transform: uppercase;
            color: rgb(34, 34, 34);
        }

        option {
            text-transform: uppercase;
        }
    }

    select {
        text-transform: uppercase;
    }

    textarea {
        height: 220px;
        text-transform: uppercase;
        display: none;
    }
}