.section-content {
    &>div {
        margin-bottom: 30px;
    }
}

.section-footer {
    bottom: 40px;
    width: 100%;
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.software-company {
    a {
        &:hover {
            color: $my-yellow;
        }
    }
}

.social-links {
    a {
        margin: 0 5px;
        cursor: pointer;
        transition: color 0.4s ease;
        font-size: 24px;
        font-family: "Garamond-Bold";
        color: rgb(185, 184, 185);

        &:hover {
            color: white;
        }
    }
}

#section8-right {
    .my-form {
        input, select, textarea {
            width: 275px !important;
        }

        textarea {
            height: 200px !important;
        }
    }
}